import React from 'react'
import { Link } from 'gatsby'

const BannerLink = ({ link }) => (
  link.slug.includes(`mailto`)
    ? <a
      href={link.slug}
      className='c-banner__btn c-btn'
    >
      {link.text}
    </a>
    : <Link
      to={link.slug}
      className='c-banner__btn c-btn'
    >
      {link.text}
    </Link>
)

export default BannerLink
