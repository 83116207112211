import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Page from 'Page'

const PageTemplate = ({ data }) => {
  const { markdownRemark: page } = data
  const {
    html,
    frontmatter
  } = page

  return (
    <Page
      title={frontmatter.title}
      content={html}
    />
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default PageTemplate

export const GenericPageQuery = graphql`
  query Page(
    $id: String!
  ) {
    markdownRemark(
      id: {
        eq: $id
      }
    ) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
