import React from 'react'

import Layout from 'Layout'
import SEO from 'SEO'
import { HTMLContent } from 'Content'

import View from './PageView'

const Page = ({ title, content, excerpt, slug }) => (
  <Layout>
    <SEO
      title={title}
      description={excerpt}
      slug={slug}
    />
    <View
      content={content}
      contentComponent={HTMLContent}
      title={title}
    />
  </Layout>
)

export default Page
