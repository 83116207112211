import React from 'react'

const BannerTitle = ({ text }) => (
  <h4
    className='c-banner__title e-h3'
  >
    {text}
  </h4>
)

export default BannerTitle
