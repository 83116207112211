import React from 'react'

const BannerText = ({ text }) => (
  <p
    className='c-banner__text'
  >
    {text}
  </p>
)

export default BannerText
