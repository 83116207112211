import React from 'react'

import Hero from 'Hero'
import Content from 'Content'
import Banner from 'Banner'

const PageView = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div
      className='c-page'
    >
      <Hero
        title={title}
      />
      <div
        className='o-container--prose'
      >
        <div
          className='u-padding-top-large u-padding-bottom-large u-padding-top-huge@lg u-padding-bottom-huge@lg'
        >
          <PageContent
            content={content}
          />
        </div>
      </div>
      <Banner />
    </div>
  )
}

export default PageView
