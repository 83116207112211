import React from 'react'

import BannerText from './BannerText'
import BannerTitle from './BannerTitle'
import BannerLink from './BannerLink'

const BannerView = ({ title, text, link, className }) => (
  <aside
    className={`c-banner ${className || ``}`}
  >
    <div
      className='o-container--thin'
    >
      { title && <BannerTitle text={title} /> }
      { text && <BannerText text={text} /> }
      { link && <BannerLink link={link} /> }
    </div>
  </aside>
)

export default BannerView
