import React from 'react'

import View from './BannerView'

const Banner = ({ title, text, link, className }) => (
  <View
    title={title}
    text={text}
    link={link}
    className={className}
  />
)

Banner.defaultProps = {
  title: `Get in touch`,
  text: `We always want to hear about new ideas, so if you have a podcast you’d like to make, let us know.`,
  link: {
    slug: `mailto:hello@stak.london`,
    text: `Get in touch`
  }
}

export default Banner
